<template>
  <nav class="navbar nav navbar-expand-lg navbar-light sticky-top shadow-sm">
    <div class="container-fluid nav__container">

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
          <li class="nav-item nav__item">
            <router-link
              to="/"
              class="nav-link nav__link"
            >
              Титульный лист
            </router-link>
          </li>

          <li class="nav-item nav__item">
            <router-link
              to="/graph"
              class="nav-link nav__link"
            >
              Генеалогическое древо
            </router-link>
          </li>

          <li class="nav-item nav__item">
            <router-link
              to="/gallery"
              class="nav-link nav__link"
            >
              Галерея
            </router-link>
          </li>

          <li class="nav-item nav__item">
            <router-link
              to="/about"
              class="nav-link nav__link"
            >
              Обо мне
            </router-link>
          </li>
        </ul>
      </div>
      
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Nav'
}
</script>

<style lang="scss">
@import '@/assets/scss/components/nav.scss';
</style>
