<template>
  <div id="app">
    <div class="layout">

      <header class="layout__header">
        <Nav />
      </header>

      <main class="layout__main">
        <router-view />
      </main>
      
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav';

export default {
  name: 'App',

  components: {
    Nav
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main.scss';
</style>
