import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'mainPage',
    component: () => import('@/pages/MainPage')
  },
  {
    path: '/graph',
    name: 'graphPage',
    component: () => import('@/pages/GraphPage')
  },
  {
    path: '/gallery',
    name: 'galleryPage',
    component: () => import('@/pages/GalleryPage')
  },
  {
    path: '/about',
    name: 'aboutPage',
    component: () => import('@/pages/AboutPage')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
